var render = function render(){
  var _vm$emojiData, _vm$emojiData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "emoji-box"
  }, [_c('p', {
    staticClass: "emoji-title"
  }, [_vm._v("commonly used")]), _vm._v(" "), _c('div', {
    staticClass: "emoji-list",
    on: {
      "click": function ($event) {
        return _vm.addEmoji($event);
      }
    }
  }, _vm._l((_vm$emojiData = _vm.emojiData) === null || _vm$emojiData === void 0 ? void 0 : _vm$emojiData.common, function (item1, index1) {
    return _c('p', {
      key: index1,
      staticClass: "emoji-item cursor-hand"
    }, [_vm._v(_vm._s(item1))]);
  }), 0), _vm._v(" "), _c('p', {
    staticClass: "emoji-title"
  }, [_vm._v("Smiles & emotion")]), _vm._v(" "), _c('div', {
    staticClass: "emoji-list",
    on: {
      "click": function ($event) {
        return _vm.addEmoji($event);
      }
    }
  }, _vm._l((_vm$emojiData2 = _vm.emojiData) === null || _vm$emojiData2 === void 0 ? void 0 : _vm$emojiData2.all, function (item2, index2) {
    return _c('p', {
      key: index2,
      staticClass: "emoji-item cursor-hand"
    }, [_vm._v(_vm._s(item2))]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }