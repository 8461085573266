<template>
  <div class="chatWindow" id="chatWindow" @dragover.prevent>
    <div class="dragChatWindow" v-show="isDragHover"></div>
    <div class="chatMoudle windowHead">
      <ChatHeader ref="chatHeader" :accInfo="accInfo" :showIAgree="showIAgree" @initScroll="initScroll" />
    </div>
    <div class="windowContent" :style="showChatList ? 'border-radius: 0 0 12px 12px' : ''" v-loading="$store.state.chatLog.pageLoading">
      <ChatMainMessage 
        ref="chatMainMessage"
        :chatMessage="chatMessage" 
        :accInfo="accInfo"
        :uploadFileList="uploadFileList" 
        v-show="!showChatList" 
        @uploadRetry="uploadRetry"
        @deleteFile="deleteFile"
        @addEmoji="addEmoji"
        @getUnreadNum="getUnreadNum"
      />
      <ChatMessageList 
        ref="chatMessageList"
        v-show="showChatList" 
        :chatRoomData="chatRoomData"
        @enterConver="enterConver" 
        @anewGetChatList="resetList" 
      />
    </div>
    <div class="chatMoudle windowFooter" v-show="!showChatList">
      <ChatFooter 
        ref="chatFooter"
        @updateUpload="updateUploadList" 
        @openEmojiModel="openEmojiModel"
      />
      <div class="chatMoudle iAgree" v-if="showIAgree">
        <div>
          This chat widget uses a cookie to interact with website visitors and to provide your chat history. To find out more about this cookie, see your 
          <span class="agreement" @click="getAgreement('cookiePolicy')">Cookie Policy</span> and <span class="agreement" @click="getAgreement('privacyPolicy')">Privacy Policy</span>.
        </div>
        <div class="iagreeBtn" @click="agree">I agree</div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatHeader from './chatHeader';
import ChatFooter from './chatFooter';
import ChatMainMessage from './chatMainMessage';
import ChatMessageList from './chatMessageList';

export default {
  components: {
    ChatHeader,
    ChatFooter,
    ChatMainMessage,
    ChatMessageList,
  },
  data() {
    return {
      showIAgree: false,
      publicRoomId: '', // 常规聊天房间号
      accInfo: {},
      isDragHover: false, // 拖拽移入
      uploadFileList: [],
      chatMessageQuery: {
        page: 1,
        size: 50,
        room_id: undefined,
      },
      chatRoomData: {
        list: [],
        count: 0
      }
    }
  },
  computed: {
    // 打开聊天chuangkou
    showChatWindow: function() {
      return this.$store.state.chatLog.showChatWindow;
    },
    // 是否展示聊天列表
    showChatList: function() {
      return this.$store.state.chatLog.showChatListWindow;
    },
    chatMessage: function() {
      return this.$store.state.chatLog.chatHistory;
    },
    chatRoomParams: function() {
      return this.$store.state.chatLog.chatRoomParams;
    },
    chatMessageListParams: function() {
      return this.$store.state.chatLog.chatMessageListParams;
    },
    // socket接收到的新消息
    updateAccount: function() {
      return this.$store.state.chatLog.updateAccount;
    },
    // 链接成功加载
    toLoadMessage: function() {
      return this.$store.state.chatLog.toLoadMessage;
    },
    detailDataEmpty: function() {
      return this.$store.state.chatLog.detailDataEmpty;
    },
    emojiData: function() {
      return this.$store.state.chatLog.emojiData;
    }
  },
  watch: {
    showChatWindow(value) {      
      if(!value){
        this.$refs.chatMainMessage.cleanData()
        this.$refs.chatFooter.openEmojiModel(false)
      }
      //  isOPenChat 如果询价，订单详情数据为空的情况下 手动唤起的聊天页面为常规聊天
      const isOPenChat = value && this.detailDataEmpty &&(this.$route.path == '/quote/detail3.0'|| this.$route.path == '/order/detail')
      if(isOPenChat||(value && this.$route.path !== '/quote/detail3.0' && this.$route.path !== '/quote/checkout' && this.$route.path !== '/order/detail')) {
        /**
         * 判断在此路由下是不是发送是的询价单或订单的消息
         */
        // const chatRoomParams = this.$store.state.chatLog.chatRoomParams;
        // const isTrue = (this.$route.path == '/quote/quoteList' && chatRoomParams.room_type==1) || (this.$route.path == '/order' && chatRoomParams.room_type==2)
        // if(isTrue) return
        // 是否是正在加载询价单房间消息
        const isUnconvRoom = this.$store.state.chatLog.isUnconvRoom;
        if (isUnconvRoom) return;
        if(!this.showIAgree) {
          // 打开重新请求聊天明细  非详情页打开常规聊天
          this.$store.commit('chatLog/setPageLoading', true)
          this.$store.commit('chatLog/setChatRoomType',null);
          if(this.publicRoomId) {
            this.$store.commit('chatLog/setChatMessageListParams',{ page: 1, size: 50, room_id: this.publicRoomId});
            this.getChatMessageData({ page: 1, size: 50, room_id: this.publicRoomId})
          } else {
            this.$store.commit('chatLog/setPageLoading', true)
            this.$getChatRoom(this.chatRoomParams).then(res => {
              this.$store.commit('chatLog/setPageLoading', false)
              if(res.code == 1) {
                this.$store.commit('chatLog/setChatMessageListParams',{ page: 1, size: 50, room_id: res.data.id});
                this.getChatMessageData({ page: 1, size: 50, room_id: res.data.id})
              } else {
                this.$message.error('Connection failed, please try again later.')
              }
            }).catch(()=>{
              this.$store.commit('chatLog/setPageLoading', false)
            })
          };
        }
      }
      if (!value) { //清楚批注
        this.$refs.chatFooter.resetFile();
        this.$store.commit('chatLog/setPartBatchFile', null)
      }
    },
    showChatList(value) {
      if(value) {
        this.$refs.chatFooter.resetFile();
        this.$refs.chatMessageList.onTabChange("all")
        this.$refs.chatMainMessage.cleanData()
        this.$refs.chatFooter.openEmojiModel(false)
      }
    },
    // 更新业务员
    updateAccount(val) {
      if(val) {
        this.getAccInfo()
        this.$store.commit('chatLog/setUpdateAccount',false)
      }
    },
    // 登录刷新
    toLoadMessage(val) {
      if(val) {
        // 是否同意隐私协议
        this.getPrivacyStatus()
        // 获取业务员信息
        this.getAccInfo()
        this.$store.commit('chatLog/setToLoadMessage',false)
      }
    }
  },
  mounted() {
    const dragDiv = document.getElementById('el-container');
    const chatWindow = document.getElementById('chatWindow')
    let vm = this;
    dragDiv.addEventListener('dragenter',function(e) {
      e.preventDefault();
      if(chatWindow.contains(e.target)) {
        vm.isDragHover = true;
      }
    });
    dragDiv.addEventListener('dragleave', function(e) {
      e.preventDefault();
      if(chatWindow.contains(e.target)) {
        return;
      }
      vm.isDragHover = false;
    });
    chatWindow.addEventListener('drop', function(event) {
      event.preventDefault();
      vm.isDragHover = false;
      vm.$refs.chatFooter.uploadType = 'uploadFile';
      let fileList = event.dataTransfer.files;
      // console.log('拖拽   放开');
      vm.$refs.chatFooter.uploadBeforce(fileList,2)
    })
    this.getEmojiList();    
  },
  methods: {
    // 获取业务员信息
    getAccInfo() {
      let vm = this;
      vm.$getAccountInfo().then(res => {
        if(res.code == 1) {
          vm.accInfo = res.data;
          vm.$store.commit('chatLog/setAccountInfo',res.data);
        } else  {
          this.$message.error(res.msg);
        }
      })
    },
    // 通过列表进入会话
    enterConver(data) {
      this.$store.commit('chatLog/setPageLoading',true);
      this.getChatMessageData(data);
      this.$refs.chatMainMessage.toScrollHeight();
    },
    // 获取聊天房间
    getChatInfo() {
      let vm = this;
      vm.$getChatRoom(vm.chatRoomParams).then(res => {
        if(res.code == 1) {
          vm.$store.commit('chatLog/setRoomData',res.data);
          // 请求聊天明细
          let chatparams = {...vm.$store.state.chatLog.chatMessageListParams};
          chatparams.room_id = res.data.id;
          chatparams.is_new = res.data.is_new;
          vm.$store.commit('chatLog/setChatMessageListParams',chatparams);
          if(res.data.is_new) {
            vm.getChatMessageData(chatparams);
          }
          if(vm.chatRoomParams.room_type == 4) {
            vm.publicRoomId = res.data.id;
          }
        } else {
          // vm.$message.error(res.msg);
          this.$message.error('Connection failed, please try again later.')
        }
      })
    },
    // 获取聊天明细
    async getChatMessageData(data) {
      let vm = this;
      vm.$store.commit('chatLog/setPageLoading', true)
      if(data.is_new) {
        // 获取欢迎语
        vm.$store.commit('chatLog/setChatHistory',{count: 0, list: [],room_type: 4});
        await vm.$getWelcomeSpeech({room_id: data.room_id}).then(res => {
          if(res.code == 1) {
            vm.$store.commit('chatLog/setChatRoomType',{room_type: 4})
            vm.$store.commit('chatLog/setChatMessageListParams',data);
            vm.$store.commit('chatLog/setPageLoading',false);
          } else  {
            this.$message.error(res.msg);
          }
        }).catch(()=>{})
      } else {
        await vm.$getChatHistory(data).then(res => {
          if(res.code == 1) {
            let arr = {...res.data};
            arr.list = res.data.list.reverse();
            // vm.chatMessage = {...arr};
            vm.$store.commit('chatLog/setChatHistory',arr);
            vm.$store.commit('chatLog/setPageLoading',false);
            // 更新未读数量
            this.$refs.chatHeader.getUnreadMsgNum();
          } else  {
            // this.$message.error(res.msg);
            this.$message.error('Connection failed, please try again later.')
          }
        }).catch(()=>{})
      };
      vm.$store.commit('chatLog/setPageLoading', false)
    },
    // 获取聊天列表
    getChatList(){
      let data = {page:1,size:20};
      setTimeout(() =>{
        this.$getChatMsgList(data).then(res => {
          if(res.code == 1) {
            this.chatRoomData = res.data;
            this.$store.commit('chatLog/setMessageList',res.data.list);
          } else {
            this.$message.error(res.msg);
          }
        })
      },500)
    },
    // 文件列表更新
    updateUploadList(data) {
      this.uploadFileList = data;
    },
    // 文件重发
    uploadRetry(item) {
      this.$refs.chatFooter.uploadRetry(item);
    },
    deleteFile(item, index) {
      this.$refs.chatFooter.deleteFile(item,index);
    },
    // 获取是否同意隐私协议
    getPrivacyStatus() {
      this.$getChatPrivacyStatus().then(res => {
        if(res.code == 1) {
          const info = res.data;
          if(info.status == 0) {
            this.showIAgree = true;
            this.$emit('showIAgree',true);
          } else {
            // 已同意
            this.showIAgree = false;
            // 获取聊天房间
            this.getChatInfo();
            // 获取聊天列表
            this.getChatList();
          }
          this.$store.commit('chatLog/setChatAgree',info.status)
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 同意隐私协议
    agree() {
      this.$agreeChatPrivacyStatus().then(res => {
        if(res.code == 1) {
          this.getPrivacyStatus();
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 重置滚动nomore状态
    initScroll() {
      this.$refs.chatMainMessage.scrollBottom = 0;
      this.$refs.chatMainMessage.noMore = false;
    },
    // 设为已读
    resetList() {
      this.$refs.chatMessageList.onTabChange("all")
      this.getChatList();
      this.$refs.chatHeader.getUnreadMsgNum();
    },
    // 查看隐私协议
    getAgreement(urlName) {
      let url = ''
      if(urlName=='cookiePolicy'){
        url = 'https://app.termly.io/document/cookie-policy/7654d22c-0441-48e0-8965-b1930a55a39e'
      }else if(urlName=='privacyPolicy'){
        url = 'https://app.termly.io/document/privacy-policy/383b32a7-2031-4bec-8177-3f251368ce6d'
      }
      // const data = this.$router.resolve({
      //   path: 'https://app.rapiddirect.com/member/agreement',
      //   query: {quote_no: roomType.relation_name, type: roomType.room_type}
      // })
      window.open(url, '_blank')
    },
    getEmojiList(){
      if(this.emojiData){
        return
      }
      this.$getEmojiList().then(res=>{
        if(res.data){
          this.$store.commit('chatLog/setEmojiData',res.data)
        }
      })
    },
    addEmoji(value){      
      if(!value){
        return
      }
      let txt = this.$refs.chatFooter.messageTxt + value
      this.$refs.chatFooter.onInputChange(txt)
      this.$refs.chatMainMessage.openEmojiModel(false)
      this.$refs.chatFooter.openEmojiModel(false)
    },
    openEmojiModel(value){
      this.$refs.chatMainMessage.openEmojiModel(value)
    },
    getUnreadNum(){
      let _vm = this
      setTimeout(()=>{
        this.$refs.chatHeader.getUnreadMsgNum();
      },1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .dragChatWindow {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    border: 1px solid #EA543F;
    box-shadow: 0px 0px 30px rgba(240, 142, 129, 0.15);
    box-sizing: border-box;
    border-radius: 18px;
  }
  .chatWindow {
    width: 460px;
    height: 600px;
    position: absolute;
    top: -620px;
    left: -408px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    .chatMoudle {
      padding: 0 20px;
      width: 100%;
      box-sizing: border-box;
    }
    .windowHead {
      height: 60px;
      background: #EA543F;
      border-radius: 16px 16px 0 0;
    }
    .windowContent {
      flex: 1;
      background: #fff;
      overflow: hidden;
    }
    .windowFooter {
      position: relative;
      min-height: 68px;
      padding: 10px 20px  20px;
      background: #fff;
      border-radius: 0 0 16px 16px;

      .iAgree {
        min-height: 142PX!important;
        padding: 20px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 142px;
        border-radius: 0 0 12px 12px;
        border-top:1px solid #D9D9D9;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .iagreeBtn {
          width: 100%;
          height: 34px;
          line-height: 34px;
          text-align: center;
          border-radius: 3px;
          background: #EA543F;
          // margin-top: 15px;
          color: #fff;
          cursor: pointer;
          margin-top: 20px;
          &:hover {
            background: #FF6550;
          }
        }

        .agreement {
          margin-top: 20px;
          color: #FF6550;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
</style>