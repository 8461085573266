<!--  -->
<template>
    <main class="emoji-box">
        <p class="emoji-title">commonly used</p>
        <div class="emoji-list" @click="addEmoji($event)">
            <p class="emoji-item cursor-hand" v-for="(item1,index1) in emojiData?.common" :key="index1">{{ item1 }}</p>
        </div>
        <p class="emoji-title">Smiles & emotion</p>
        <div class="emoji-list" @click="addEmoji($event)">
            <p class="emoji-item cursor-hand" v-for="(item2,index2) in emojiData?.all" :key="index2">{{ item2 }}</p>
        </div>
    </main>
</template>

<script>
export default {
    name: 'emojiView',
    computed: {
        emojiData: function() {
            return this.$store.state.chatLog.emojiData;
        }
    },
    methods: {
        addEmoji(e){
            let classList = Array.from(e.target.classList)
            if(!classList.includes('emoji-item')){
                return
            }
            this.$emit('addEmoji',e.target.innerText)
        }
    },
}
</script>
<style lang='scss' scoped>
.emoji-box{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    .emoji-title{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 5px;
    }
    .emoji-list{
        display: flex;
        flex-wrap: wrap;
        max-height: 90px;
        overflow-y: auto;
        padding-bottom: 10px;
        .emoji-item {
            width: 35px;
            height: 30px;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            border-radius: 5px;
            user-select: none; 
            &:hover{
                background-color: rgba(245, 245, 245, 1);
            }
        }
        
    }
}
</style>
