var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "megListDiv"
  }, [_c('div', {
    staticClass: "msgListTitle"
  }, [_c('div', {
    staticClass: "tabs"
  }, [_c('span', {
    class: _vm.active == 'all' ? 'tabsItem activeTab' : 'tabsItem',
    on: {
      "click": function ($event) {
        return _vm.onTabChange('all');
      }
    }
  }, [_vm._v("All")]), _vm._v(" "), _c('span', {
    class: _vm.active == 'Unread' ? 'tabsItem activeTab' : 'tabsItem',
    on: {
      "click": function ($event) {
        return _vm.onTabChange('Unread');
      }
    }
  }, [_vm._v("Unread")])]), _vm._v(" "), _c('div', {
    staticClass: "setAllRead",
    on: {
      "click": _vm.onSetAllread
    }
  }, [_vm._v("Set all as read")])]), _vm._v(" "), _c('div', {
    staticClass: "msgListCon",
    attrs: {
      "id": "msgListCon"
    },
    on: {
      "scroll": _vm.scrollChange
    }
  }, [_c('div', {
    staticClass: "msgListConScroll",
    attrs: {
      "id": "msgListConScroll"
    }
  }, _vm._l(_vm.messageList, function (item) {
    var _item$records;
    return _c('div', {
      key: item.id,
      staticClass: "msgRow",
      on: {
        "click": function ($event) {
          return _vm.onEnterConver(item);
        }
      }
    }, [_c('div', {
      staticClass: "imgAndname"
    }, [_c('div', {
      staticClass: "imgBox"
    }, [_c('span', {
      staticClass: "imgRedio"
    }, [!_vm.accountInfo.headImg ? _c('img', {
      staticClass: "headImg",
      attrs: {
        "src": require("assets/images/defaultHead.png")
      }
    }) : _c('img', {
      staticClass: "headImg",
      attrs: {
        "src": _vm.imgUrl + _vm.accountInfo.headImg
      }
    })]), _vm._v(" "), item.unread_num && item.unread_num > 0 ? _c('span', {
      staticClass: "unreadNum"
    }, [_vm._v(_vm._s(item.unread_num > 99 ? '99+' : item.unread_num))]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "listTxtbox"
    }, [item.relation_name ? _c('div', {
      staticClass: "nameAndRelation"
    }, [_c('div', {
      staticClass: "listHead",
      attrs: {
        "title": item.relation_name
      }
    }, [_c('span', {
      staticClass: "nickname"
    }, [_vm._v(_vm._s(_vm.accountInfo.nickname))]), _vm._v(" "), _c('span', {
      staticClass: "relationSpan"
    }, [item.extra_params.quote_id ? _c('img', {
      staticStyle: {
        "width": "14px",
        "height": "14px"
      },
      attrs: {
        "src": require("assets/images/quoteLog.svg")
      }
    }) : _c('img', {
      staticStyle: {
        "width": "14px",
        "height": "14px"
      },
      attrs: {
        "src": require("assets/images/orderLog.svg")
      }
    }), _vm._v("\n                  [" + _vm._s(item.relation_name) + "]\n                ")])]), _vm._v(" "), _c('span', {
      staticClass: "timeDiv"
    }, [_vm._v("\n                " + _vm._s(_vm.getShowTime(item.records.create_time)) + "\n              ")])]) : _c('div', {
      staticClass: "nameAndRelation"
    }, [_c('span', {
      staticClass: "nickname"
    }, [_vm._v(_vm._s(_vm.accountInfo.nickname))]), _vm._v(" "), _c('span', {
      staticClass: "timeDiv"
    }, [_vm._v("\n                " + _vm._s(_vm.getShowTime(item.records.create_time)) + "\n              ")])]), _vm._v(" "), _c('div', {
      staticClass: "msgCon"
    }, [item.records.is_revoke === 1 ? _c('div', {
      staticClass: "revoke-text"
    }, [_vm._v(_vm._s(item.records.user_type === 1 ? 'You' : item.records.nickname) + " recalled a message.")]) : (_item$records = item.records) !== null && _item$records !== void 0 && _item$records.content ? _c('div', {
      staticClass: "msgText"
    }, [_vm._v(_vm._s(_vm._f("escape")(item.records.content)))]) : item.records && item.records.talk_records_file && item.records.talk_records_file.length && item.records.talk_records_file[0].file_type == 6 ? _c('div', [_vm._v("[Picture]")]) : _c('div', [_vm._v("[Attachment]")])])])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }