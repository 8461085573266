import { render, staticRenderFns } from "./chatMainMessage.vue?vue&type=template&id=35172544&scoped=true"
import script from "./chatMainMessage.vue?vue&type=script&lang=js"
export * from "./chatMainMessage.vue?vue&type=script&lang=js"
import style0 from "./chatMainMessage.vue?vue&type=style&index=0&id=35172544&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35172544",
  null
  
)

export default component.exports