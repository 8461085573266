var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sendFooter"
  }, [_c('div', {
    class: _vm.isActive ? 'sendBox activeSendBox' : 'sendBox',
    style: _vm.networkBreak ? {
      background: '#F5F7FA'
    } : {}
  }, [_c('div', {
    staticClass: "sendInputBox"
  }, [_c('el-input', {
    staticClass: "borderNone",
    attrs: {
      "id": "sendInput",
      "type": "textarea",
      "maxlength": "2000",
      "disabled": _vm.networkBreak,
      "placeholder": "Write a message"
    },
    on: {
      "input": _vm.onInputChange,
      "focus": function ($event) {
        _vm.isActive = true;
      },
      "blur": function ($event) {
        _vm.isActive = false;
      }
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onSend.apply(null, arguments);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.keydown.apply(null, arguments);
      }
    },
    model: {
      value: _vm.messageTxt,
      callback: function ($$v) {
        _vm.messageTxt = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "messageTxt"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.openEmojiModel();
      }
    }
  }, [_vm.emojiOpen ? _c('img', {
    staticClass: "chatImg",
    attrs: {
      "src": require("assets/images/emoji-icon-open.svg"),
      "alt": ""
    }
  }) : _c('img', {
    staticClass: "chatImg",
    attrs: {
      "src": require("assets/images/emoji-icon-close.svg"),
      "alt": ""
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "more-icon cursor-hand",
    class: {
      'more-open': _vm.showMoreModel
    },
    on: {
      "click": function ($event) {
        _vm.showMoreModel = !_vm.showMoreModel;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/plus-icon.svg"),
      "alt": ""
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "sendBtn"
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadFileList.some(function (item) {
        return item.status == 'ready';
      }) || _vm.uploadFileList.length == 0 && !_vm.showSubBtn || _vm.sendFoldering || _vm.networkBreak,
      expression: "uploadFileList.some(item=> item.status == 'ready') || (uploadFileList.length == 0 && !showSubBtn) || sendFoldering ||  networkBreak"
    }],
    staticClass: "chatSend",
    attrs: {
      "src": require("assets/images/chatSend.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadFileList.length > 0 && !_vm.uploadFileList.some(function (item) {
        return item.status == 'ready';
      }) && !_vm.sendFoldering && !_vm.networkBreak || _vm.showSubBtn && !_vm.networkBreak && !_vm.sendFoldering && !_vm.uploadFileList.some(function (item) {
        return item.status == 'ready';
      }),
      expression: "(uploadFileList.length > 0 && !uploadFileList.some(item=> item.status == 'ready') && !sendFoldering && !networkBreak) || (showSubBtn && !networkBreak && !sendFoldering && !uploadFileList.some(item=> item.status == 'ready'))"
    }],
    staticClass: "chatSend2",
    attrs: {
      "src": require("assets/images/chatSend2.svg")
    },
    on: {
      "click": _vm.onSend
    }
  })]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMoreModel,
      expression: "showMoreModel"
    }],
    staticClass: "more-list"
  }, [_c('div', {
    staticClass: "more-list-item cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.onSelectFile('uploadFileImg');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/chatImg3.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v("Gallery")]), _vm._v(" "), _c('input', {
    ref: "uploadFileImg",
    attrs: {
      "type": "file",
      "hidden": "",
      "accept": ".png,.jpg,.jpeg,.jpe,.jfif,.bmp,.gif,.svg,.webp,.tif,.tiff",
      "multiple": ""
    },
    on: {
      "change": _vm.uploadBeforce
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "more-list-item cursor-hand",
    on: {
      "click": function ($event) {
        return _vm.onSelectFile('uploadFile');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/file3.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v("Document")]), _vm._v(" "), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "hidden": "",
      "accept": ".png,.jpg,.jpeg,.jpe,.jfif,.bmp,.gif,.svg,.webp,.tif,.tiff,.pdf,.pptx,.doc,.docx,.xlsx,.xls,.stl,.stp,.step,.sldprt,.igs,.iges,.x_t,.x_b,.prt,.ipt,.CatPart,.sat,.3dxml,.eps,.ai,.psd,.dwg,.dws,.mp4,.mpeg,.wmv,.mov,.avi,.mp3,.wma,.wav,.acc,.ogg,.flac,.ape,.zip,.rar,.7z,.tgz,.gz",
      "multiple": ""
    },
    on: {
      "change": _vm.uploadBeforce
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }