import Vue from 'vue'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)
const Cookie = require('js-cookie') ;

const socket = {
  websock: null,
  ws_url: process.env.socketUrl + '/wss/default.io/?token=RD_User:' + Cookie.get('auth'),
  // 开启标识
  socket_open: false,
  // 心跳timeer
  hearbeat_timer: null,
  // 心跳发送频率
  hearbeat_interval: 15000,
  // 是否重新重连
  is_reonnect: true,
  // 重连次数
  reconnect_count: 3,
  // 已发起重连次数
  reconnect_current: 1,
  // 重连timer
  reconnect_timer: null,
  // 重连频率
  reconnect_interval: 3000,

  // 初始化连接
  init: () => {
    if(!("WebSocket" in window)) {
      console.log('浏览器不支持websocket');
      return null;
    };
    //  已经创建过连接不再重复创建
    if(socket.websock && socket.socket_open) {
      return socket.websock;
    };
    if(!Cookie.get('auth')) {
      return;
    }
    // 创建
    socket.websock = new WebSocket(process.env.socketUrl + '/wss/default.io/?token=RD_User:' + Cookie.get('auth'));
    socket.websock.onmessage = function (e) {
      socket.receive(e);
    };
    // 关闭连接
    socket.websock.onclose = function (e) {
      clearInterval(socket.hearbeat_interval)
      socket.socket_open = false

      // 需要重新连接
      if (socket.is_reonnect) {
        socket.reconnect_timer = setTimeout(() => {
          // 超过重连次数
          if (socket.reconnect_current > socket.reconnect_count) {
            clearTimeout(socket.reconnect_timer)
            return
          }

          // 记录重连次数
          socket.reconnect_current++
          socket.reconnect()
        }, socket.reconnect_interval)
      }
    };
    // 连接成功
    socket.websock.onopen = function () {
      socket.socket_open = true
      socket.is_reonnect = true
      // 开启心跳
      socket.heartbeat();
      window.$nuxt.$store.commit('chatLog/setToLoadMessage',true)
    }
    // 连接发生错误
    socket.websock.onerror = function () {
      console.log('WebSocket连接发生错误,token=',Cookie.get('auth'))
    };
  },

  // 发送消息
  send: (data, callback = null) => {
    // 开启状态直接发送
    // if(socket.websock.readyState === socket.websock.OPEN) {
      socket.websock.send(JSON.stringify(data));

      if (callback) {
        callback()
      }
    // } else if(socket.websock.readyState === socket.websock.CONNECTING) {
    //   // 正在开启状态，则等待1s后重新调用
    //   setTimeout(function () {
    //     socket.send(data, callback)
    //   }, 1000)

    // } else {
    //   // 未开启，则等待1s后重新调用
    //   socket.init();
    //   console.log('发送完消息是否一直init',data);
    //   // setTimeout(function () {
    //   //   socket.send(data, callback)
    //   // }, 1000)
    // }
  },

  // 接收消息
  receive: (message) => {
    let params = JSON.parse(message.data);
    let content = params.content;
    if(params.event == "event_talk") {
      if(params.messageType === "Render") {
        window.$nuxt.$store.commit('quoteData/receivePushRenderData', params);
        return;
      }
      let msgData = {...content.recordsd_info};

      console.log('msgData',msgData);
      
      if(msgData.msg_type == 5) {
        // 更换业务员
        window.$nuxt.$store.commit('chatLog/setUpdateAccount',true)
        return;
      }
      // 消息撤回
      if(msgData.msg_type === 9){
        pushMessage(content)
      }
      if(msgData.msg_type == 2 || msgData.msg_type == 3) {
        // setTimeout(()=> {
          pushMessage(content);
        // },500)
      } else if(msgData.msg_type == 7) {
        // 后台所要此时客户端实时时间
        const area = dayjs.tz.guess();
        // 推送当前时间到后台
        onPushTime(content,area)
      } else if(msgData.msg_type == 1 || msgData.msg_type == 2 || msgData.msg_type == 3 || msgData.msg_type == 4) {
        pushMessage(content);
      }
    }
    if(!params || params.event == "heartbeat") {
      return false;
    };
    window.$nuxt.$store.commit('chatLog/setSocketMsg',params);
  },

  // 心跳
  heartbeat: () => {
    if (socket.hearbeat_timer) {
      clearInterval(socket.hearbeat_timer)
    };
    socket.hearbeat_timer = setInterval(() => {
      var data = {event: 'heartbeat'}
      socket.send(data)
    }, socket.hearbeat_interval)
  },

  // 主动关闭链接
  close: () => {
    console.log('主动断开socket连接')
    clearInterval(socket.hearbeat_timer)
    socket.is_reonnect = false
    socket.websock.close()
    socket.websock = null
  },

  // 重新连接
  reconnect: () => {
    if (socket.websock && socket.socket_open) {
      socket.websock.close()
    }

    socket.init()
  },
};

Vue.prototype.$socket = socket;

function pushMessage (content) {
  let msgData = {...content.recordsd_info};
  let memberInfo = {...content.member_info};
  console.log('pushMessage',content);
  let roomData = window.$nuxt.$store.state.chatLog.roomData;  // 房间信息
  let accountInfo = window.$nuxt.$store.state.chatLog.accountInfo;  //业务员信息 
  let messageRommList = JSON.parse(JSON.stringify(window.$nuxt.$store.state.chatLog.messageList)); // 消息明细
  // 当前房间(是不是聊天列表或者关闭)
  let showMessageWindow = window.$nuxt.$store.state.chatLog.showChatWindow;  // 是否开着聊天窗
  let showListPage = window.$nuxt.$store.state.chatLog.showChatListWindow;   // 是否展示的是聊天列表
  // 非工作时间后台回复消息需要切换在线状态
  if(!accountInfo.is_work_time && msgData.msg_type == 1) {
    window.$nuxt.$store.commit('chatLog/setCheckOnlineStatus',true);
  }
  // 是否为当前房间的id
  if(roomData.id == content.room_id) {
    if(showListPage || !showMessageWindow) {
      // 更新未读数量
      window.$nuxt.$store.commit('chatLog/setUpdateUnreadNum',{isCurrentRoom: false,update: true});
      // 更新消息列表
      let arr = messageRommList.map(item => {
        let newItem = {...item};
        if(item.id == content.room_id) {
          newItem.records.content = msgData.content;
          newItem.create_time = content.last_msg_time * 1000;
          newItem.talk_records_file = msgData.record_file ? msgData.record_file : null;
          newItem.unread_num = newItem.unread_num + 1;
          newItem.records.user_type = 2;
          newItem.records.is_revoke = msgData.msg_type === 9 ? 1 : 0;
          newItem.records.nickname = memberInfo.full_name;
        }
        return newItem;
      });
      messageRommList = [...arr];
      window.$nuxt.$store.commit('chatLog/setMessageList',messageRommList);
    } else {
      window.$nuxt.$store.commit('chatLog/setUpdateUnreadNum',{isCurrentRoom: true,update: true});
    };
    // 返回信息如果是当前房间的操作
    let data = JSON.parse(JSON.stringify(window.$nuxt.$store.state.chatLog.chatHistory));
    if(msgData.msg_type === 9){
      // 清除回复消息弹框
      let replyData = window.$nuxt.$store.state.chatLog.replyData
      if(replyData && replyData.id === msgData.id){
        window.$nuxt.$store.commit('chatLog/setReplyData',null);
      }
      // 设置消息类型
      data.list.forEach(item => {
        if(item.id === msgData.id){
          item.is_revoke = 1
        }
        if(item.cite_id && item.cite_id === msgData.id){
          item.cite_record.is_revoke = 1
        }
      });
    }else{
      //  接收新消息
      let newData = {
        content: msgData.content ? msgData.content : undefined,
        create_time: dayjs.utc(content.last_msg_time * 1000).tz('Asia/Shanghai').format("YYYY-MM-DD HH:mm"),
        extra_params: msgData.extra_params,
        is_sender: content.sender_type == 2 ? 0 : 1,
        msg_type: msgData.msg_type,
        nickname: accountInfo.nickname,
        room_user_id: content.member_info.id,
        talk_records_file: msgData.record_file,
        id: Number(msgData.id),
        is_revoke: 0,
      }
      // 添加引用的消息
      if(!Array.isArray(msgData.cite_msg_info) && msgData.cite_msg_info){
        let { id, content, msg_type, is_revoke = 0, im_talk_records_file_info = [], user_type, extra_params } = msgData.cite_msg_info
        newData.cite_id = 
        newData.cite_record = {
          id,
          content,
          msg_type,
          is_revoke,
          user_type,
          extra_params,
          talk_records_file:im_talk_records_file_info,
        }
      }
      if(msgData.msg_type == 3) {
        newData.is_sender == 1;
      }
      data.list.push(newData)
    }
    console.log('setChatHistory',data);
    window.$nuxt.$store.commit('chatLog/setChatHistory',data);
  } else {
    // 不是当前房间则是更新未读数量
    window.$nuxt.$store.commit('chatLog/setUpdateUnreadNum',{isCurrentRoom: false,update: true});
    if(showListPage) {
      // 更新消息列表
      let isFind = false;
      let arr = messageRommList.map(item => {
        let newItem = {...item};
        if(item.id == content.room_id) {
          newItem.records.content = msgData.content;
          newItem.records.create_time = content.last_msg_time * 1000;
          newItem.talk_records_file = msgData.record_file ? msgData.record_file : null;
          newItem.unread_num = newItem.unread_num + 1;
          newItem.records.user_type = 2;
          newItem.records.is_revoke = msgData.msg_type === 9 ? 1 : 0;
          newItem.records.nickname = memberInfo.full_name;
          // 找到了匹配的房间
          isFind = true;
        }
        return newItem;
      });
      if(!isFind) {
        // 后台新建聊天房间
        arr.unshift({
          extra_params: content.extra_params,
          relation_name: content.extra_params ? content.extra_params.relation_name : '',
          id: content.room_id,
          room_type: content.room_type,
          last_msg_time: content.last_msg_time,
          unread_num: 1,
          records: {
            content: msgData.content,
            create_time: content.last_msg_time * 1000,
            extra_params: msgData.extra_params ? msgData.extra_params : null,
            id: new Date().getTime() + Math.ceil(Math.random() * 10000),
            talk_records_file: msgData.record_file ? msgData.record_file : null,
            user_type: 2,
            is_revoke: msgData.msg_type === 9 ? 1 : 0,
            nickname: memberInfo.full_name,
          }
        })
      }
      messageRommList = [...arr];
      window.$nuxt.$store.commit('chatLog/setMessageList',messageRommList);
    }
  }
}

// 推送前台时间(时区)
function onPushTime(content,area) {
  if(!area) return;
  let room_id = content.room_id;
  window.$nuxt.$pushCurrentTime({
    room_id,
    client_time: area
  })
} 
