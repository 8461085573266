<template>
  <div class="megListDiv">
    <div class="msgListTitle">
      <div class="tabs">
        <span :class="active == 'all' ? 'tabsItem activeTab' : 'tabsItem'" @click="onTabChange('all')">All</span>
        <span :class="active == 'Unread' ? 'tabsItem activeTab' : 'tabsItem'" @click="onTabChange('Unread')">Unread</span>
      </div>
      <div class="setAllRead" @click="onSetAllread">Set all as read</div>
    </div>
    <div class="msgListCon" id="msgListCon" @scroll="scrollChange">
      <div class="msgListConScroll" id="msgListConScroll">
        <div
          v-for="item in messageList"
          :key="item.id"
          class="msgRow"
          @click="onEnterConver(item)"
        >
          <div class="imgAndname">
            <div class="imgBox">
              <span class="imgRedio">
                <img class="headImg" src='~assets/images/defaultHead.png' v-if="!accountInfo.headImg" />
                <img class="headImg" :src="imgUrl + accountInfo.headImg" v-else />
              </span>
              <span v-if="item.unread_num && item.unread_num > 0" class="unreadNum">{{item.unread_num > 99 ? '99+' : item.unread_num}}</span>
            </div>
            <div class="listTxtbox">
              <div class="nameAndRelation" v-if="item.relation_name">
                <div class="listHead" :title="item.relation_name">
                  <span class="nickname">{{accountInfo.nickname}}</span>
                  <span class="relationSpan">
                    <img style="width: 14px;height:14px" src="~assets/images/quoteLog.svg" v-if="item.extra_params.quote_id">
                    <img style="width: 14px;height:14px" src="~assets/images/orderLog.svg" v-else>
                    [{{item.relation_name}}]
                  </span>
                </div>
                <span class="timeDiv">
                  {{getShowTime(item.records.create_time)}}
                </span>
              </div>
              <div class="nameAndRelation" v-else>
                <span class="nickname">{{accountInfo.nickname}}</span>
                <span class="timeDiv">
                  {{getShowTime(item.records.create_time)}}
                </span>
              </div>
              <div class="msgCon">
                <div v-if="item.records.is_revoke === 1" class="revoke-text">{{ item.records.user_type === 1 ? 'You' : item.records.nickname }} recalled a message.</div>
                <div v-else-if="item.records?.content" class="msgText" >{{item.records.content | escape}}</div>
                <div v-else-if="item.records && item.records.talk_records_file && item.records.talk_records_file.length && item.records.talk_records_file[0].file_type == 6">[Picture]</div>
                <div v-else>[Attachment]</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTIme from "dayjs/plugin/relativeTime";
import updateLocale from 'dayjs/plugin/updateLocale'
import { debounce } from '../../../libs/tool';
dayjs.extend(relativeTIme);
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: "%s",
    past: "%s",
    s: 'Now',
    m: "1 min. ago",
    mm: "%d mins. ago",
    h: "1 hr. ago",
    hh: "%d hrs. ago",
    d: "1 day ago",
    dd: "%d days ago",
    M: "1 mo. ago",
    MM: "%d mos. ago",
    y: "1 yr. ago",
    yy: "%d yrs. ago"
  }
})
  export default {
    props: {
      chatRoomData: {
        type: [Object],
        default: {
          count: 0,
          list: []
        }
      }
    },
    data() {
      return {
        active: 'all',
        imgUrl: '',
        noMore: false,
        loading: false,
        listParams: {
          size: 20,
          page: 1,
          is_unread: null,
        },
        messageList: [],
        unreadMsgList: []
      }
    },
    computed: {
      accountInfo: function() {
        return this.$store.state.chatLog.accountInfo;
      },
      msgList: function() {
        return this.$store.state.chatLog.messageList;
      }
    },
    watch: {
      msgList(val) {
        if(val) {
          this.messageList = this.$store.state.chatLog.messageList;
        }
      }
    },
    mounted() {
      this.getImgUrl()
    },
    methods: {
      // tab切换
      onTabChange(tab) {
        this.active = tab;
        if(tab == 'all') {
          let data = this.listParams;
          data.page = 1;
          data.is_unread = null;
          this.getMsgList(data)
        } else {
          let data = this.listParams;
          data.page = 1;
          data.is_unread = 1;
          this.getMsgList(data);
        }
      },
      // 获取聊天房间列表
      getMsgList(data) {
        let vm = this;
        this.$getChatMsgList(data).then(res => {
          if(res.code == 1) {
            vm.listParams = data;
            this.$store.commit('chatLog/setMessageList',res.data.list);
            vm.loading = false;
          } else {
            vm.loading = false;
            this.$message.error(res.msg);
          }
        })
      },
      // 进入会话
      onEnterConver(item) {
        let params = {page:1,size:50,room_id:item.id}
        this.$emit('enterConver',params);
        // 设置房间信息以及房间聊天明细请求参数
        this.$store.commit('chatLog/setRoomData',item);
        this.$store.commit('chatLog/setChatMessageListParams',params);
        // 指定房间类型
        if(item.room_type == 4) {
          this.$store.commit('chatLog/setChatRoomType',null)
        } else {
          this.$store.commit('chatLog/setChatRoomType',{room_type: item.room_type,relation_name: item.relation_name})
        }
        this.$store.commit('chatLog/setShowChatListWindow',false);
      },
      // 拼接图片Url
      getImgUrl() {
        this.imgUrl = process.env.baseOss;
      },
      // 设置所有已读
      onSetAllread() {
        let data = {read_type: 2};
        this.$clearUnreadMsg(data).then(res => {
          if(res.code == 1) {
            this.$emit('anewGetChatList');
            this.onTabChange('all')
          } else  {
            this.$message.error(res.msg);
          }
        })
      },
      getShowTime(time) {
        let utc = dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
        let formTime = dayjs(time).subtract(8,'hour').format('YYYY-MM-DD HH:mm:ss');
        let t = dayjs(formTime).from(utc);
        return t;
      },
      // 滚动条翻页
      scrollChange: debounce(function(e) {
        let vm = this;
        let conScrollHeight = e.target.scrollHeight   // 可以滚动区域的高度
        let conClientHeight = e.target.clientHeight   // 区域内容的高度
        let conScrollTop = e.target.scrollTop        //  内容滚动了的高度
        // 滚动条距离底部距离
        vm.scrollBottom = conScrollHeight - conClientHeight - conScrollTop;
        if(vm.messageList.length >= vm.chatRoomData.count) {
          vm.noMore = true;
          return;
        } else {
          vm.noMore = false;
        }
        if(vm.noMore || vm.loading) {
          return;
        };
        if(vm.scrollBottom < 10) {
          let size = vm.chatRoomData.count
          if(size > vm.messageList.length) {
            vm.loading = true;
            let data = vm.listParams;
            data.size = data.size + 20;
            vm.getMsgList(data);
          }
        }
      },200),
    }
  }
</script>

<style lang="scss" scoped>
  .megListDiv{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 16px;
    .item {
      margin-top: 10px;
      margin-right: 40px;
    }
    .headImg {
      display: inline-block;
      width: 46px;
      height: 46px;
    }
    .msgListTitle {
      width: 100%;
      height: 34px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 12px;
      color: #252525;
      font-weight: 600;
      .tabs {
        height: 100%;
        .tabsItem {
          display: inline-block;
          margin-right: 17px;
          cursor: pointer;
          &:hover {
            color: #EA543F;
          }
        }
        .activeTab {
          height: 100%;
          color: #EA543F;
          border-bottom: 2px solid #EA543F;
        }
      }
      .setAllRead {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration-line: underline;
        &:hover {
          color: #EA543F;
        }
      }
    }
    .msgListCon {
      font-size: 12px;
      flex: 1;
      overflow-y: auto;
      scrollbar-gutter: stable;
    }
    .msgListConScroll {
      overflow-x: hidden;
    }
    .msgRow {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 11px 0 20px;
      cursor: pointer;
      height: 86px;
      font-size: 12px;
      color: #252525;
      &:hover {
        background: #f3f3f3;
      }
      .nameAndRelation {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 18px;
      }
      .listHead {
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 20px;
      }
      .imgAndname {
        display: flex;
        align-items: center;
        .nickname {
          display: inline-block;
          
          font-weight: 500;
          margin-right: 8px;
        }
        .relationSpan {
          font-size: 12px;
          color: #878787;
          font-weight: 400;
        }
        .listTxtbox {
          width: calc(100% - 77px);
          height: 46px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .imgBox {
          height: 46px;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          color: #fff;
          .imgRedio {
            display: inline-block;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #f5f5f5;
          }
          .unreadNum {
            min-width: 7px;
            text-align: center;
            position: absolute;
            top: -8px;
            left: 28px;
            padding: 0 6px;
            background: #EA543F;
            border: 1px solid #fff;
            border-radius: 14px;
            transform: scale(0.85);
            -webkit-transform: scale(0.85);
          }
        }
        .timeDiv {
          color: #878787;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .msgCon {
        font-weight: 500;
        .revoke-text{
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #D0D0D0;
        }
        .msgText {
          width: 100%;
          overflow: hidden;
          white-space:nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>