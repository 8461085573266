import { set } from "lodash"

export const state = () => ({
  showChatWindow: false,  // 是否开着聊天窗
  showChatListWindow: false,  // 是否展示的是聊天列表
  toLoadMessage: false,
  socketMsg: {}, // socket接收到的信息
  updateAccount: false, // 更换业务员
  roomData: {}, // 聊天房间
  chatHistory: {}, // 聊天明细
  messageList: [], // 消息列表
  unreadMsgNumber: 0, // 未读消息数量
  accountInfo: {}, // 业务员信息
  uploadFileList: [], // 上传图片等附件
  chatRoomType: null, // 房间类型
  chatRoomParams: { room_type: 4, relation_id: '' }, //  获取房间参数
  chatMessageListParams: { page: 1, size: 50, room_id: undefined }, // 获取房间聊天明细参数
  updateUnreadNum: null, // 更新未读消息
  unreadNum: undefined, // 未读消息数量
  networkBreak: false, //网络断开
  showQuoteChat: false, // 打开询价单聊天窗口
  showOrderChat: false, // 打开订单聊天窗口
  pageLoading: false, // 聊天窗口loading
  partBatchFile: null, //零件批注图片
  chatAgree: 1, //是否同意隐私协议 0不同意 1同意
  checkOnlineStatus: false, // 非工作时间切换在线状态
  isUnconvRoom: false, // 正在加载非常规聊天
  detailDataEmpty: false,//当询价, 订单详情数据为空时 点击聊天入口为常规聊天
  emojiData: null, //表情数据
  replyData: null   //回复数据
})

export const mutations = {
  setShowChatWindow(state, data) {
    state.showChatWindow = data
  },
  setShowChatListWindow(state, data) {
    state.showChatListWindow = data
  },
  setSocketMsg(state, data) {
    if (data.fd) {
      state.socketMsg = data
    }
  },
  setPartBatchFile(state, data) {
    state.partBatchFile = data
  },
  setRoomData(state, data) {
    state.roomData = { ...data }
  },
  setChatHistory(state, data) {
    state.chatHistory = Object.assign({}, data)
  },
  setMessageList(state, data) {
    state.messageList = data
  },
  setUnreadMsgNumber(state, data) {
    state.unreadMsgNumber = data
  },
  setAccountInfo(state, data) {
    state.accountInfo = data
  },
  setUploadFileList(state, data) {
    state.uploadFileList = Object.assign({}, data)
  },
  setChatRoomType(state, data) {
    state.chatRoomType = data
  },
  setChatRoomParams(state, data) {
    state.chatRoomParams = data
  },
  setChatMessageListParams(state, data) {
    state.chatMessageListParams = data
  },
  setUpdateAccount(state, data) {
    state.updateAccount = data
  },
  setToLoadMessage(state, data) {
    state.toLoadMessage = data
  },
  setUpdateUnreadNum(state, data) {
    state.updateUnreadNum = data
  },
  setUnreadNum(state, data) {
    state.unreadNum = data
  },
  setNetworkBreak(state, data) {
    state.networkBreak = data
  },
  setShowQuoteChat(state, data) {
    state.showQuoteChat = data
  },
  setShowOrderChat(state, data) {
    state.showOrderChat = data
  },
  setPageLoading(state, data) {
    state.pageLoading = data
  },
  setChatAgree(state, data) {
    state.chatAgree = data
  },
  setCheckOnlineStatus(state, data) {
    state.checkOnlineStatus = data;
  },
  setIsUnconvRoom(state, data) {
    state.isUnconvRoom = data;
  },
  setDetailEmpty(state, data) {
    state.detailDataEmpty = data
  },
  // emoji表情数据
  setEmojiData(state, data) {
    state.emojiData = data
  },
  // 回复消息数据
  setReplyData(state, data){
    state.replyData = data
  }
}
