var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address-win"
  }, [_c('el-dialog', {
    attrs: {
      "visible": _vm.show,
      "show-close": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.show = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "dialog-win"
  }, [_c('div', {
    staticClass: "dialog-left"
  }, [_c('img', {
    style: {
      width: '100%',
      height: '100%'
    },
    attrs: {
      "src": require("@/assets/images/create_account.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "dialog-right"
  }, [_c('div', {
    staticClass: "dialog-close",
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "el-icon-close"
  })]), _vm._v(" "), _c('div', {
    staticClass: "align-center",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "login-content"
  }, [_c('div', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v("Create Account")])]), _vm._v(" "), _c('el-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.firstStep,
      expression: "firstStep"
    }],
    ref: "ruleForm1",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.ruleForm1,
      "rules": _vm.rules1,
      "label-position": "top",
      "hide-required-asterisk": true
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "email",
      "label": "Email address"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-input', {
    attrs: {
      "disabled": _vm.ncvisible,
      "placeholder": "name@company.com"
    },
    model: {
      value: _vm.ruleForm1.email,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm1, "email", $$v);
      },
      expression: "ruleForm1.email"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "mgb20 passwordHint mart16"
  }, [_vm._v("Sign up with work email for exclusive benefits and services.\n          "), _c('el-tooltip', {
    attrs: {
      "placement": "bottom",
      "popper-class": "tooltip_Additional"
    }
  }, [_c('div', {
    staticClass: "align-center cfff",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('span', {
    staticClass: "tip"
  }, [_vm._v("\n                • Teamspace collaboration"), _c('br'), _vm._v(" • Additional payment options"), _c('br'), _vm._v(" • Cost optimization advice"), _c('br'), _vm._v(" • More exclusive privileges on request. \n              ")])]), _vm._v(" "), _c('i', {
    staticClass: "el-icon-info cursor-hand c000 tipsIcon"
  })])], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "prop": "password",
      "label": "Password"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-input', {
    ref: "password1",
    attrs: {
      "disabled": _vm.ncvisible,
      "type": _vm.pwdType_password1,
      "placeholder": "Enter your password"
    },
    model: {
      value: _vm.ruleForm1.password,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm1, "password", $$v);
      },
      expression: "ruleForm1.password"
    }
  }, [_c('template', {
    slot: "suffix"
  }, [_c('span', {
    staticClass: "showPassWord",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.changePwdType('password1');
      }
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password1 == 'text',
      expression: "pwdType_password1=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/openEyes.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password1 !== 'text',
      expression: "pwdType_password1!=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/closeEyes.svg")
    }
  })])])], 2)], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ruleForm1.password || _vm.ruleForm1.password.length == 0,
      expression: "!ruleForm1.password || ruleForm1.password.length == 0"
    }],
    staticClass: "mgb20 passwordHint"
  }, [_c('div', {
    staticClass: "passHint"
  }, [_c('span', {
    staticClass: "redioSpan"
  }), _vm._v(" "), _c('span', [_vm._v("At least 1 letter")])]), _vm._v(" "), _c('div', {
    staticClass: "passHint"
  }, [_c('span', {
    staticClass: "redioSpan"
  }), _vm._v(" "), _c('span', [_vm._v("At least 1 number")])]), _vm._v(" "), _c('div', {
    staticClass: "passHint"
  }, [_c('span', {
    staticClass: "redioSpan"
  }), _vm._v(" "), _c('span', [_vm._v("At least 8 characters")])])]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm1.password && _vm.ruleForm1.password.length > 0,
      expression: "ruleForm1.password && ruleForm1.password.length > 0"
    }],
    staticClass: "mgb20 passwordHint"
  }, [_c('div', {
    class: _vm.passHint.hint1 ? 'passHint passHint-g' : 'passHint passHint-r'
  }, [_vm.passHint.hint1 ? _c('i', {
    staticClass: "el-icon-success"
  }) : _c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" "), _c('span', {
    staticClass: "passHint-txt"
  }, [_vm._v("At least 1 letter")])]), _vm._v(" "), _c('div', {
    class: _vm.passHint.hint2 ? 'passHint passHint-g' : 'passHint passHint-r'
  }, [_vm.passHint.hint2 ? _c('i', {
    staticClass: "el-icon-success"
  }) : _c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" "), _c('span', {
    staticClass: "passHint-txt"
  }, [_vm._v("At least 1 number")])]), _vm._v(" "), _c('div', {
    class: _vm.passHint.hint3 ? 'passHint passHint-g' : 'passHint passHint-r'
  }, [_vm.passHint.hint3 ? _c('i', {
    staticClass: "el-icon-success"
  }) : _c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" "), _c('span', {
    staticClass: "passHint-txt"
  }, [_vm._v("At least 8 characters")])])]), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "prop": "passwordTo",
      "label": "Confirm password"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-input', {
    ref: "password2",
    attrs: {
      "disabled": _vm.ncvisible,
      "type": _vm.pwdType_password2,
      "placeholder": "Enter your password again"
    },
    model: {
      value: _vm.ruleForm1.passwordTo,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm1, "passwordTo", $$v);
      },
      expression: "ruleForm1.passwordTo"
    }
  }, [_c('template', {
    slot: "suffix"
  }, [_c('span', {
    staticClass: "showPassWord",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.changePwdType('password2');
      }
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password2 == 'text',
      expression: "pwdType_password2=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/openEyes.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password2 !== 'text',
      expression: "pwdType_password2!=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/closeEyes.svg")
    }
  })])])], 2)], 1)], 1), _vm._v(" "), _c('el-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.firstStep,
      expression: "!firstStep"
    }],
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.ruleForm,
      "rules": _vm.rules,
      "label-position": "top",
      "hide-required-asterisk": true
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "firstName",
      "label": "First name"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-input', {
    attrs: {
      "disabled": _vm.ncvisible,
      "placeholder": "Enter your first name"
    },
    model: {
      value: _vm.ruleForm.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "firstName", $$v);
      },
      expression: "ruleForm.firstName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "lastName",
      "label": "Last name"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-input', {
    attrs: {
      "disabled": _vm.ncvisible,
      "placeholder": "Enter your last name"
    },
    model: {
      value: _vm.ruleForm.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "lastName", $$v);
      },
      expression: "ruleForm.lastName"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "companyItem",
    attrs: {
      "prop": "companyName",
      "label": "Company name"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": _vm.ncvisible,
      "placeholder": "Enter your company name"
    },
    model: {
      value: _vm.ruleForm.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "companyName", $$v);
      },
      expression: "ruleForm.companyName"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "prop": "jobRole",
      "label": "Job role"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-select', {
    ref: "surOption",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "disabled": _vm.ncvisible,
      "filterable": "",
      "popper-class": "selectItem",
      "placeholder": "Select your job role"
    },
    model: {
      value: _vm.ruleForm.jobRole,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "jobRole", $$v);
      },
      expression: "ruleForm.jobRole"
    }
  }, _vm._l(_vm.jobList, function (group) {
    return _c('el-option-group', {
      key: group.id,
      attrs: {
        "label": group.label
      }
    }, _vm._l(group.options, function (item) {
      return _c('el-option', {
        key: item.value,
        attrs: {
          "label": item.label,
          "value": item.value
        }
      }, [item.value === -1 ? _c('div', {
        staticClass: "otherOptionDiv",
        on: {
          "click": function ($event) {
            $event.stopPropagation();
          }
        }
      }, [_c('div', {
        staticClass: "otherMat"
      }, [_c('el-input', {
        attrs: {
          "placeholder": "Other Job",
          "maxlength": 64
        },
        model: {
          value: _vm.otherJob,
          callback: function ($$v) {
            _vm.otherJob = $$v;
          },
          expression: "otherJob"
        }
      })], 1), _vm._v(" "), _c('el-button', {
        on: {
          "click": function ($event) {
            return _vm.onChangeJob('surOption');
          }
        }
      }, [_vm._v("Save")])], 1) : _vm._e()]);
    }), 1);
  }), 1)], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "prop": "phoneNumber",
      "label": "Phone number"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.firstPhoneNum || _vm.flagImgSrc,
      expression: "ruleForm.firstPhoneNum || flagImgSrc"
    }],
    staticClass: "flagImg"
  }, [_c('img', {
    attrs: {
      "src": _vm.flagImgSrc,
      "width": "20px",
      "height": "15px"
    }
  })]), _vm._v(" "), _c('el-input', {
    staticClass: "input-with-select",
    attrs: {
      "placeholder": "Enter your phone number",
      "disabled": _vm.ncvisible
    },
    model: {
      value: _vm.ruleForm.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "phoneNumber", $$v);
      },
      expression: "ruleForm.phoneNumber"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend",
      "value": _vm.ruleForm.firstPhoneNum,
      "filterable": "",
      "popper-class": "phoneSelect",
      "filter-method": _vm.filterMethod
    },
    on: {
      "visible-change": _vm.visibleChange,
      "change": _vm.onFlagChange
    },
    slot: "prepend"
  }, [_vm._l(_vm.countryOptions, function (item) {
    return _c('el-option', {
      key: item.en + item.tel,
      attrs: {
        "label": item.tel,
        "value": item
      }
    }, [[_c('div', {
      staticClass: "optionDiv"
    }, [_c('span', {
      staticClass: "optionImg"
    }, [_c('img', {
      attrs: {
        "src": item.flag,
        "alt": "",
        "width": "20px",
        "height": "15px"
      }
    })]), _vm._v(" "), _c('span', {
      staticClass: "countryTel"
    }, [_vm._v(_vm._s(item.tel))]), _vm._v(" "), _c('span', [_vm._v(_vm._s(item.en))])])]], 2);
  }), _vm._v(" "), _c('template', {
    slot: "empty"
  }, [_c('p', {
    staticClass: "noData"
  }, [_vm._v("No data")])])], 2)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "agreement"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "agreement"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }])
  }, [_c('el-checkbox-group', {
    staticStyle: {
      "line-height": "14px"
    },
    model: {
      value: _vm.ruleForm.agreement,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "agreement", $$v);
      },
      expression: "ruleForm.agreement"
    }
  }, [_c('el-checkbox', {
    attrs: {
      "name": "agreement"
    }
  }, [_vm._v("\n                I agree with the\n                "), _c('a', {
    staticClass: "agreeLink",
    attrs: {
      "href": "https://app.termly.io/document/terms-of-service/658e05ae-a1fc-48e3-8b5a-6e59c7156558",
      "target": "_blank"
    }
  }, [_vm._v("\n                Terms and Conditions")]), _vm._v(" "), _c('span', [_vm._v("and")]), _vm._v(" "), _c('a', {
    staticClass: "agreeLink",
    attrs: {
      "href": "https://app.termly.io/document/privacy-policy/383b32a7-2031-4bec-8177-3f251368ce6d",
      "target": "_blank"
    }
  }, [_vm._v("Privacy Policy")])])], 1)], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "registerBtn"
  }, [_c('el-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ncvisible && !_vm.firstStep,
      expression: "!ncvisible && !firstStep"
    }],
    attrs: {
      "id": "AB_SignUpFinishButton",
      "loading": _vm.registerLoading,
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitValid('ruleForm');
      }
    }
  }, [_vm._v("Sign Up")]), _vm._v(" "), _c('el-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ncvisible && _vm.firstStep,
      expression: "!ncvisible && firstStep"
    }],
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.next('ruleForm1');
      }
    }
  }, [_vm._v("Next Step")]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ncvisible,
      expression: "ncvisible"
    }]
  }, [_c('div', {
    ref: "ncRegister",
    attrs: {
      "id": "ncRegister"
    }
  })])], 1), _vm._v(" "), _c('div', {
    staticClass: "login_in fwn"
  }, [_c('span', [_vm._v("Already have an account?")]), _vm._v(" "), _c('span', {
    staticClass: "loginTxt",
    attrs: {
      "id": "AB_SignInTextLink"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Log In")])])], 1)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }