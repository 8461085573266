<template>
  <div id="nav" class="userHeader align-center">
    <div :class="{'userHeader-l':true,'userHeader-l-hide':!$store.state.aside}">
      <el-tooltip content="Go back to RapidDirect main site" placement="bottom">
        <div class="aside-header cursor-hand" @click="gowebsite">
          <img v-if="$store.state.aside" class="applogo" src="../assets/img/applogo.svg" alt />
          <img v-else class="applogo-min" src="../assets/img/logo_min.svg" alt />
        </div>
      </el-tooltip>
      <!-- <div class="logo-ling">
        <span class="logo-title">{{ quoteTitle }}</span>
      </div> -->
    </div>
    <template v-if="$route.path!='/help-center/specifications-policy'">
      <div v-if="$store.state.auth" class="user-info">
        <el-badge
          v-if="$store.state.auth"
          class="mgr10 badge cursor-hand"
          :value="UnreadNum === 0 ? null : UnreadNum"
        >
          <el-tooltip content="Message" placement="bottom">
            <svg-icon icon-class="msg" :width="px2rem('25px')" :height="px2rem('25px')" @click.native="openMess"></svg-icon>
          </el-tooltip>
        </el-badge>
        <el-dropdown trigger="click" @visible-change="visibleChange" @command="commandSelect">
          <div class="user cursor-hand">
            <div class="aside-card-user">
              <img v-if="userData.imageUrl" :src="userData.imageUrl" />
              <div v-else class="aside-card-user-name">{{ icon_name }}</div>
            </div>
            <i v-if="!dropShow" class="el-icon-arrow-down el-icon--right"></i>
            <i v-else class="el-icon-arrow-up el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="myaccount">My Account</el-dropdown-item>
            <el-dropdown-item command="logout">Log out</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div :class="{'userHeader-login':true,'userHeader-login-hide':!$store.state.aside}" v-else>
        <span class="aside-card-login" @click="toRegister">Sign Up</span><span class="aside-line">|</span><span class="aside-card-login" @click="Login">Log In</span>
      </div>
    </template>
  </div>
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined
import { px2rem } from '~/libs/tool'
export default {
  name: 'UserHeader',
  data() {
    return {
      routerData: [
        {
          title: 'New quote',
          path: '/',
          url: '/',
          active: true,
        },
        {
          title: 'My quotes',
          path: '/quote/quoteList',
          url: '/quote/quoteList',
          active: false,
        },
        {
          title: 'My orders',
          path: '/order',
          url: '/order',
          active: false,
        },
      ],
      UnreadNum: null,
      userName: '',
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0,
      },
      quoteTitle: '',
      icon_name: '',
      userData: {
        imageUrl: "",
      },
      dropShow: false,
    }
  },
  computed: {
    listData() {
      return this.$store.state.avatar
    },
    getUnreadNum() {
      return this.$store.state.msg.UnreadNum
    },
    getUserName() {
      return this.$store.state.msg.userName
    },
    getEmail() {
      return this.$store.state.msg.email
    },
  },
  watch: {
    "$store.state.msg.userName"(val){
      if(val){
         this.icon_name = val.slice(0, 1).toUpperCase()
      }
    },
    listData(val) {
      if (!val) {
        if (this.$store.state.auth) {
          // this.userData.imageUrl = require('assets/images/login_bg.svg')
          this.icon_name = localStorage
            .getItem('userName')
            ?.slice(0, 1)
            .toUpperCase()
        } else {
          this.userData.imageUrl = require('assets/images/login_default.svg')
          this.icon_name = ''
        }
      } else {
        this.$store.dispatch('msg/getUrl')
        this.userData.imageUrl = val
        this.icon_name = ''
      }
    },
    $route(to, from) {
      this.routerData.forEach((item) => {
        if (item.path === to.path) {
          item.active = true
        } else {
          item.active = false
        }
      })
      this.getQuoteTitle(to.path)
    },
    getUnreadNum(val) {
      this.UnreadNum = val
    },
  },
  mounted() {
    this.getUser()
    this.getMsg()
    this.getQuoteTitle(this.$route.path)
    // this.getMsg()
  },
  created() {
    this.changeRouter(this.$route.path)
  },
  methods: {
    px2rem,
    commandSelect(name) {
      if (name === 'logout') {
        this.logout()
      } else if (name === 'myaccount') {
        this.toUrl()
      }
    },
    getQuoteTitle(path) {
      
      if (this.$store.state.indexRouters.includes(path)) {
        this.quoteTitle = 'New quote'
      } else if (path === '/quote/detail3.0') {
        this.quoteTitle = 'Quote detail'
      } else if (path === '/quote/checkout') {
        this.quoteTitle = 'Check out'
      } else if (path === '/user') {
        this.quoteTitle = ' My Account'
      } else if (path === '/quote/quoteList') {
        this.quoteTitle = 'My quotes'
      } else if (path === '/order') {
        this.quoteTitle = 'My orders'
      } else if (path === '/order/detail') {
        this.quoteTitle = 'Order detail'
      } else if (path === '/msg') {
        this.quoteTitle = 'My Notifications'
      } else if (path === '/quote/rfq') {
        this.quoteTitle = 'Request a Quote'
      } else if (path === '/connorsTeam/newQuote') {
        this.quoteTitle = 'New team quote'
      }else {
        this.quoteTitle = ''
      }
    },
    getUser() {
      this.$getUser().then((res) => {
        this.userData = res.data
        localStorage.setItem('emailValidated', res.data.email_validated)
        this.icon_name = ''
        if (!res.data.imageUrl) {
          if (this.$store.state.auth) {
            // this.userData.imageUrl = require('assets/images/login_bg.svg')
            this.icon_name = localStorage
              .getItem('userName')
              ?.slice(0, 1)
              .toUpperCase()
          } else {
            this.userData.imageUrl = require('assets/images/login_default.svg')
          }
        }
      })
    },
    visibleChange(bol) {
      this.dropShow = bol
    },
    openMess() {
      this.$store.commit('openMessage', true)
    },
    toUrl() {
      if (this.$store.state.auth) {
        this.$router.push({
          path: '/user',
        })
      }
    },
    logout() {
      const data = { userToken: Cookie.get('auth') }
      this.$logout(data).then((res) => {
        if (res.code === 1) {
          this.UnreadNum = 0
          this.$data.userData = this.$options.data().userData
          this.icon_name = ''
          const VisibleSolidWorksGuideObj = localStorage.getItem('VisibleSolidWorksGuideObj')
          // 使外部API上的JWT Cookie无效 hteij[{}]
          this.$store.commit('msg/setUnreadNum', 0)
          this.$store.commit('msg/setTeamName', '')
          this.$store.commit('msg/setUserName', '')
          this.$store.commit('msg/setEmail', '')
          Cookie.remove('auth')
          Cookie.remove('isadd')
          Cookie.remove('avatar')
          Cookie.remove('userName')
          this.$store.commit('setAuth', null)
          this.$store.commit('setAvatar', null)
          this.$store.commit('checkQuote', 0)
          localStorage.clear()
          this.$router.push('/')
          this.$store.commit('setVerifyNumber', 0);
          localStorage.removeItem('emailCountDown'); 
          localStorage.removeItem('createTeam/createTeamDetailId')
          this.$store.commit('createTeam/setCreateTeamDetailId','')
          localStorage.removeItem('createTeam/setCreateTeamListData')
          this.$store.commit('createTeam/setCreateTeamListData', '')
          // 断开websocket
          this.$socket.close();
          this.$store.commit('chatLog/setChatHistory',{count: 0, list: [],room_type: 4})
          localStorage.setItem('VisibleSolidWorksGuideObj', VisibleSolidWorksGuideObj)
        }
      })
    },
    toRegister() {
      this.$store.commit('openRegister', true)
      this.$store.commit('setLoginType', 'aside')
    },
    Login() {
      this.$store.commit('openLogin', true)
      this.$store.commit('setLoginType', 'aside')
    },
    changeRouter(rout) {
      const vm = this.routerData
      vm.forEach((item) => {
        item.active = false
      })
      for (let i = 0; i < vm.length; i++) {
        if (vm[i].path === rout) {
          vm[i].active = true
          return
        }
      }
    },
    gowebsite() {
      window.open('https://www.rapiddirect.com')
    },
    // 获取角标
    getMsg() {
      const vm = this.tableShow
      const data = {
        size: vm.pageSize,
        page: vm.currentPage,
        status: -10,
      }
      this.$getMsg(data).then((res) => {
        this.UnreadNum = res.data.unread
      })
    },
  },
}
</script>

<style scoped lang="scss">
.logo-title {
  margin-left: 75px;
  color: #252525;
  font-size: 20px;
  font-weight: 500;
}
.userHeader {
  height: 67px;
  position: relative;
  background: $white;
  overflow: hidden;
  justify-content: space-between;
  .userHeader-l {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
  .userHeader-l-hide {
    margin-left: 30px;
  }
  .userHeader-login{
     margin-right: 60px;
  }
  .userHeader-login-hide{
     margin-right: 30px;
  }
}
.user-info {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  .badge {
    margin-top: 5px;
  }
  /deep/ .el-badge__content {
    font-size: 12px;
    scale: 0.83;
    background-color: #ea543f;
  }
  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83px;
    height: 38px;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    margin-left: 24px;
    .aside-card-user {
      height: 25px;
      width: 25px;
      text-align: center;
      line-height: 25px;
      border-radius: 50%;
      overflow: hidden;
      background: #ebeef2;
      margin-right: 13.9px;
      .aside-card-user-name {
        color: #ea543f;
        font-weight: 500;
        font-size: 14px;
      }
      img {
        width: 100%;
        height: 100%;
        vertical-align: unset!important;
       }
      }
  }
}
/deep/ .el-dropdown-menu__item {
  font-weight: 500;
  font-size: 14px;
  color: #252525;
  &:hover {
    color: #252525 !important;
    background-color: #f6f6f6 !important;
  }
}
.aside-line{
  color: #D0D0D0; 
  margin: 0 12px;
}
.aside-card-login {
  font-weight: 500;
  font-size: 14px;
  color: #252525;
  cursor: pointer;
  &:hover{
    color: #ea543f;
  }
}
.icon-message {
  vertical-align: super;
  width: 80px;
  height: 40px;
  cursor: pointer;
}
.aside-header {
  text-align: center;
  background: $white;
  .logoText {
    color: $c1B1C21;
    font-size: 18px;
    margin-left: 15px;
    font-weight: bold;
  }
  .applogo {
    width: 100px;
    height: 33px;
  }
  .applogo-min {
    width: 32px;
    height: 33px;
  }
}
</style>
