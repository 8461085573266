<template>
  <div class="aside" :class="[openAside ? 'aside-open' : 'aside-close']">
    <div class="aside-cont">
      <div class="aside-cont-items">
      <!-- 菜单路由 -->
       <div v-for="(item, index) in routerData" :key="'route-item-'+index">
        <div class="is-line" v-if="item.type=='createTeam'" >
          <!-- <div v-if="openAside" class="title_work">Team work</div>
          <img style="cursor:auto;" :style="`width: ${px2rem('32px')};height:${px2rem('16px')};`" src="~assets/images/beta.svg" alt=""> -->
          <p>Team work</p>
        </div>
        <div class="aside-cont-item" :class="item.active ? 'active' : ''"  @click="changeRouter(item.path)" >
          <div :id="item.type=='createTeam'?'home-guide-step4':''" class="align-center aside-cont-item-c">
            <div v-if="openAside">
              <div v-if="item.icon" class="aside-btn-link icon">
                <svg class="svg" aria-hidden="true">
                  <use :xlink:href="item.icon" />
                </svg>
              </div>
              <div v-else-if="item.iconSvg" class="aside-btn-link icon">
                <img class="svg" :src="item.iconSvg" alt="">
              </div>
              <div v-else class="aside-btn-link icon img">
                <span :class="item.elIcon"></span>
              </div>
            </div>
            <div v-else :class="['aside-btn-link icon', item.elIcon ? 'img' : '']">
              <el-tooltip v-if="item.icon" effect="dark" :content="item.title" placement="right">
                <svg class="svg" aria-hidden="true">
                  <use :xlink:href="item.icon" />
                </svg>
              </el-tooltip>
              <el-tooltip v-else-if="item.iconSvg" effect="dark" :content="item.title" placement="right">
                <img class="svg" :src="item.iconSvg" alt="">
              </el-tooltip>
              <el-tooltip v-else effect="dark" :content="item.title" placement="right">
                <span :class="item.elIcon"></span>
              </el-tooltip>
            </div>
            <span v-if="openAside" class="text">{{ item.title }}</span>
          </div>
        </div>
      </div>
     <!-- 团队列表 -->
        <div class="team-menu-box">
          <div v-for="item in teamListData" :key="'team-menu-' + item.id">
            <div class="aside-cont-item" :class="item.id==teamActive ? 'active' : ''"  @click="changeTeam(item.id)">
              <div class="align-center aside-cont-item-c">
                <div v-if="openAside">
                  <div class="aside-btn-link teamN">
                    <span>{{ item.team_name&&item.team_name.slice(0, 1).toUpperCase()}}</span>
                  </div>
                </div>
                <div v-else>
                  <el-tooltip effect="dark" :content="item.team_name" placement="right">
                  <div class="aside-btn-link teamN">
                    <span>{{ item.team_name&&item.team_name.slice(0, 1).toUpperCase()}}</span>
                  </div>
                  </el-tooltip>
                </div>
                <span v-if="openAside" class="text">{{ item.team_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="['aside-info-open aside-cont-open', openAside ? '' : 'open-show']"
      @click="clickAside">
      <svg-icon v-if="openAside" :width="px2rem('24px')" :height="px2rem('24px')" icon-class="hide"></svg-icon>
      <svg-icon v-else :width="px2rem('24px')" :height="px2rem('24px')" icon-class="show"></svg-icon>
    </div>
  </div>
</template>

<script>
// import Logo from '~/components/LogoPure.vue'
import teamQuoteMixin from '~/libs/teamQuoteMixin.js'
import { px2rem } from '~/libs/tool'
export default {
  components: {
    // Logo
  },
  mixins:[teamQuoteMixin],
  data() {
    return {
      teamActive:'',
      routerData: [
        {
          title: 'New Quote',
          path: '/quote/blank',
          url: '/quote/blank',
          active: true,
          elIcon: 'el-icon-circle-plus',
        },
        {
          title: 'My Quotes',
          path: '/quote/quoteList',
          url: '/quote/quoteList',
          active: false,
          icon: '#rd-a-Group37',
        },
        {
          title: 'My Orders',
          path: '/order',
          url: '/order',
          active: false,
          // icon: '#rd-a-Group39',
          iconSvg: require('assets/images/tab-order.svg')
        },
        {
          title: 'My Molds',
          path: '/molds',
          url: '/molds',
          active: false,
          iconSvg: require('assets/images/molds.svg'),
        },{
          title: 'Create team',
          path: '/createTeam',
          url: '/createTeam',
          active: false,
          iconSvg: require('assets/images/Group3116.svg'),
          type:'createTeam'
        }],
      teamListData:[],
      userData: {
        imageUrl: require('assets/images/login_default.svg'),
      },
      UnreadNum: null,
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0,
      },
      default_avatar: require('assets/images/login_default.svg'),
      menuCont: false,
      icon_name: '',
      loginType:'openLogin' //打开的是登录弹框还是注册弹框
    }
  },
  computed: {
    listData() {
      return this.$store.state.avatar
    },
    getUnreadNum() {
      return this.$store.state.msg.UnreadNum
    },
    getUserName() {
      return this.$store.state.msg.userName
    },
    getEmail() {
      return this.$store.state.msg.email
    },
    openAside() {
      return this.$store.state.aside
    },
    auth() {
      return this.$store.state.auth
    },
  },
  watch: {
    listData(val) {
      if (!val) {
        if (this.$store.state.auth) {
          this.userData.imageUrl = require('assets/images/login_bg.svg')
          this.icon_name = localStorage
            .getItem('userName')
            ?.slice(0, 1)
            .toUpperCase()
        } else {
          this.userData.imageUrl = require('assets/images/login_default.svg')
          this.icon_name = ''
        }
      } else {
        this.$store.dispatch('msg/getUrl')
        this.userData.imageUrl = val
        this.icon_name = ''
      }
    },
    //监听登录框
    "$store.state.dialogLogin"(val){
      const register = this.$store.state.register
      if(!val&&!register&&!this.auth&&!this.$store.state.indexRouters.includes(this.$route.path)){
        this.$router.replace('/')
      }
    },
    auth: {
      handler(e, i) {
        //登录框不需要打开的路由
        const excudeRoute = ['/member/findPassword','/member/resetPassword','/member/agreement','/member/login','/member/register','/help-center/specifications-policy']
        //带加入团队参数链接过来的
        const link = this.$store.state.indexRouters.includes(this.$route.path) && this.$route.query.invitation_code
        this.$nextTick(() => {
          if(!e && link){
            const path = this.$route.path
            // if(path=='/a'){
            //   this.loginType = 'openRegister'
            // }else{
            //   this.loginType = 'openLogin'
            // }
            this.loginType = 'openRegister'
            if(this.$store.state.indexRouters.includes(this.$route.path)){
              this.$store.commit('setIndexRoute',path)
            }
            localStorage.removeItem('createTeam/setCreateTeamListData')
            this.$store.commit('createTeam/setCreateTeamListData', '')
            if(link||excudeRoute.some(item=>item==this.$route.path)) return
            this.$store
              .dispatch('wantLogin', { visible: true, loginType:this.loginType})
          }
        })
      },
    },
    $route(to, from) {
      if (to.path === from.path) {
        return false
      }
      this.routerData.forEach((item) => {
        if (item.path === to.path) {
           item.active = true
           this.teamActive = ''
          //  localStorage.removeItem('createTeam/createTeamDetailId')
        } else {
          if (item.path === '/quote/blank' && this.$store.state.indexRouters.includes(to.path)) {
            item.active = true
            this.teamActive = ''
            // localStorage.removeItem('createTeam/createTeamDetailId')
          } else {
            item.active = false
            this.teamActive = localStorage.getItem('createTeam/createTeamDetailId')
          }
        }
      })
    },
    getUnreadNum(val) {
      this.UnreadNum = val
    },
    getUserName(val) {
      this.userData.userName = val
    },
    getEmail(val) {
      this.userData.email = val
    },
    '$store.state.createTeam.createTeamListData': {
      handler(val) {
        this.teamListData = val?JSON.parse(val):[]
        // this.teamActive = this.$route.query.createTeamDetailId || ''
      },
     deep:true
    },
    '$store.state.resize': function (e) {
      if (e < 1361) {
        this.$store.commit('setAside', false)
      }
    },
  },
  mounted() {
    this.getUser()
    this.getMsg()
    //防止刷新缓存丢失
    let teamListData = localStorage.getItem('createTeam/setCreateTeamListData')
    teamListData&&this.$store.commit('createTeam/setCreateTeamListData', teamListData)
    this.teamListData = teamListData?JSON.parse(teamListData):[]
    if(this.$route.path=='/connorsTeam' || this.$route.query.team_id){
      this.teamActive = localStorage.getItem('createTeam/createTeamDetailId')
    }
  },
  created() {
    const path = this.$route.path
    // if(path=='/a'){
    //   this.loginType = 'openRegister'
    // }else{
    //   this.loginType = 'openLogin'
    // }
    this.loginType = 'openRegister'
    if(this.$store.state.indexRouters.includes(this.$route.path)){
      this.$store.commit('setIndexRoute',path)
    }
    if (this.$store.state.indexRouters.includes(this.$route.path) && this.$route.fullPath) {
        this.teamActive = ''
        // localStorage.setItem('createTeam/createTeamDetailId', '')
    } else {
      this.changeRouter(this.$route.path)
    }
    this.validRoute()
  },
  methods: {
    px2rem,
    changeRouter(rout) {
      if(rout == '/createTeam') {
        if (this.$store.state.auth) {
          return this.$store.commit('createTeam/openCreateTeam',true)
        }
        this.Login()
        return
      }
      const vm = this.routerData
      vm.forEach((item) => {
        item.active = false
      })
      // if(this.$route.path=="/connorsTeam"&&this.$route.query.is_first) {
      //   return false //团队引导中 点击不进行下一步
      // }
      for (let i = 0; i < vm.length; i++) {
        if (vm[i].path === rout) {
          vm[i].active = true
          vm.teamActive = ''
          // localStorage.removeItem('createTeam/createTeamDetailId')
          if (rout === '/') return this.toUrl('/quote/blank')
          this.toUrl(vm[i].url)
        }
      }
      
    },
    //判断是不是链接跳转过来的团队数据
    validRoute(){
      //用户获取邀请码
      if(!!localStorage.getItem('createTeam/invitation_code')){
          localStorage.removeItem('createTeam/invitation_code')
          localStorage.removeItem('createTeam/inviterEmail')
      }
      if(this.$route.query.invitation_code){
        let data = {
          invitation_code:this.$route.query.invitation_code,
          id:this.$route.query.team_id//团队id
        }
        return this.getInvitationCodeData(data)
      }
      //获取团队数据
      this.getCreateTeamList()
    },
    getInvitationCodeData(data){
      this.$getInvitationCodeData(data).then(res => {
          if (res.code === 1) {
            let response = res.data
            //链接失效
            if(!response || response instanceof Array) {
              this.$message.error('Invitation link has expired')
              return this.$router.replace('/')
            }
            localStorage.setItem('createTeam/inviterEmail', response.user_email)
            this.$store.commit('setInviterCode', data.invitation_code)
            localStorage.setItem('createTeam/invitation_code', data.invitation_code)
            localStorage.setItem('createTeam/createTeamDetailId', data.id)
            if(!this.auth){
              //response.is_reg => 判断邀请的用户是否已注册
              !response.is_reg && this.$store.commit('openRegister', true)
              response.is_reg && this.$store.commit('openLogin', true)
              return
            }
            //判断邀请的邮箱和当前登录的邮箱是否一致
            const current_email = localStorage.getItem('mail')
            if(current_email && current_email != response.user_email)  return this.$router.replace('/')
            //加入团队
            this.joinCreateTeam()
          }
      })
    },
    changeTeam(itemId){
      // if(this.$route.path=="/connorsTeam"&&this.$route.query.is_first) {
      //   return false //团队引导中 点击不进行下一步
      // }
      this.teamActive = itemId
      localStorage.setItem('createTeam/createTeamDetailId', itemId)
      this.$router.push({
          path: '/connorsTeam',
          query: {
            createTeamDetailId:itemId
          },
        })
    },
    // 获取角标
    getMsg() {
      const vm = this.tableShow
      const data = {
        size: vm.pageSize,
        page: vm.currentPage,
        status: -10,
      }
      this.$getMsg(data).then((res) => {
        this.UnreadNum = res.data.unread
      })
    },
    getUser() {
      this.$getUser().then((res) => {
        this.userData = res.data
        this.icon_name = ''
        if (!res.data.imageUrl) {
          if (this.$store.state.auth) {
            this.userData.imageUrl = require('assets/images/login_bg.svg')
            this.icon_name = localStorage
              .getItem('userName')
              ?.slice(0, 1)
              .toUpperCase()
          } else {
            this.userData.imageUrl = require('assets/images/login_default.svg')
          }
        }
      })
    },
    Login() {
      this.$store.commit(this.loginType, true)
      this.$store.commit('setLoginType', 'aside')
    },
    menuBut() {
      this.menuCont ? (this.menuCont = false) : (this.menuCont = true)
    },
    clickAside() {
      this.$store.commit('setAside', !this.openAside)
    },
    toUrl(url) {
      if (this.$store.state.auth) {
        this.$router.push({
          path: url,
        })
      } else if (url === '/quote/blank') {
        this.$router.push({
          path: url,
        })
        //location.reload()
      } else {
        this.Login()
      }
    },
    openMess() {
      this.$store.commit('openMessage', true)
    },
  },
}
</script>

<style scoped lang="scss">
.aside-open {
  width: 220px;
}
.aside-close {
  width: 90px;
}
.is-line {
  padding: 0 16px;
  margin-top: 10px;
  p {
    padding-left: 18px;
    white-space: nowrap;
    overflow: hidden;
    color: #878787;
    font-family: Poppins;
    font-size: 14px;
    margin-bottom: 18px;
  }
}
.is-line::before {
  content: '';
  display: block;
  height: 1px;
  background-color: #eee;
  margin-bottom: 40px;
}
.aside {
  height: 100%;
  min-width: 90px;
  z-index: 1000;
  background: $white;
  transition: width 0.3s;
  font-family: 'Poppins';
  filter: drop-shadow(2px 2px 3px rgba(74, 84, 104, 0.2));
  overflow: hidden;
  &-cont {
    background: $white;
    height: 100%;
    &-items {
      display: flex;
      flex-direction: column;
      height: calc(100% - 110px);
      overflow: hidden;
      padding: 30px 0;
      .title {
        padding-left: 20%;
        color: $cB1BACC;
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 3vh;
      }
      /* 隐藏默认滚动条 */
      .team-menu-box {  
        flex: 1;
        overflow: hidden;
        position: relative;
      }

      /* 当鼠标移入时显示滚动条 */
      .team-menu-box:hover {
        overflow: auto;
      }
      /* 自定义滚动条样式 */
      .team-menu-box::-webkit-scrollbar {
          width: 4px;
      }
    }
    &-item {
      box-sizing: border-box;
      display: flex;
      height: 50px;
      width: 100%;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      padding-left: 20px;
      margin-bottom: 20px;
      &.active {
        border-right: 3px solid $primary;
        background-color: #fff0f0;
        .aside-btn-link {
          border-color: #ea543f;
        }
      }
      &.active .text {
        color: #ea543f;
      }
      .icon {
        // display: inline-block;
      }
      .text {
        color: #1b1c21;
        font-size: 16px;
        line-height: 54px;
        font-weight: 500;
        vertical-align: top;
        margin: 0 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover .text {
        color: #ea543f;
      }
    }
    .aside-cont-item-c{
      padding-left: 10px;
    }
    &-quote {
      position: relative;
      width: 90%;
      height: 64px;
      background: $primary;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      cursor: pointer;
      .text {
        color: $white;
        font-size: 18px;
        line-height: 64px;
        margin: 0 20%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
      }
      .icon {
        position: absolute;
        right: 6px;
        top: 7px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background: $white;
        line-height: 50px;
        text-align: center;
        font-size: 28px;
        color: $primary;
      }
    }
    &-open {
      position: absolute;
      left: 33px;
      bottom: 20px;
    }
  }
  &-card {
    position: absolute;
    bottom: 0;
    padding: 7vh 0;
    width: 100%;
    background: $white;
    .icon-box {
      position: absolute;
      top: 54px;
      border: 2px solid rgba(177, 186, 204, 0.25);
      border-radius: 40px;
      box-sizing: border-box;
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: $c8C93A1;
      font-size: 24px;
      cursor: pointer;
      .el-badge {
        vertical-align: initial;
      }
    }
    .icon-left {
      left: 10%;
    }
    .icon-right {
      right: 10%;
    }
    .icon-deas {
      line-height: 40px;
    }
    &-user {
      position: relative;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      border-radius: 30px;
      margin: 0 auto 12px;
      overflow: hidden;
      border: 2px solid $cC8CEDA;
      .img {
        height: 100%;
      }
      &-name {
        position: absolute;
        width: 100%;
        top: 0;
        text-align: center;
        line-height: 80px;
        font-size: 22px;
        font-weight: bold;
        color: #ea543f;
      }
    }
    &-name {
      font-size: 14px;
      color: $c4A5468;
      margin-bottom: 40px;
      text-align: center;
      font-weight: bold;
    }
    &-login {
      width: 109px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      background: $cF4F4F8;
      text-align: center;
      color: $primary;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  &-header {
    line-height: 62px;
    text-align: center;
    background: $white;
    .logoText {
      color: $c1B1C21;
      font-size: 18px;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  &-box {
    width: 30px;
    border-radius: 39px;
    margin: 7vh auto 3vh;
    padding: 3vh 0 2vh;
  }

  /deep/ .el-tooltip {
    font-size: 30px;
    color: #ea543f;
    width: 30px;
    height: 100%;
  }
  &-btn {
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      background: $white;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #d0d0d0;
      text-align: center;
      .svg {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 30px;
        color: $primary;
      }
    }
    .aside-btn-link-active {
      border-right: 3px solid $primary;
      background-color: #fff0f0;
      color: $c4A5468;
    }
  }
  .img {
    border: none;
    background: none;
    span {
      font-size: 30px;
      color: $primary;
    }
  }
  &-quote {
    display: block;
    width: 54px;
    height: 54px;
    background: $primary;
    font-size: 24px;
    color: $white;
    border-radius: 54px;
    margin: 0 auto;
    line-height: 54px;
    text-align: center;
    cursor: pointer;
  }
  &-start {
    width: 40px;
    height: 40px;
    background: $cF4F4F8;
    font-size: 24px;
    color: $primary;
    border-radius: 40px;
    margin: 0 auto;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  &-info {
    width: 78px;
    border-radius: 39px;
    margin: 5vh auto 0;
    padding: 2vh 0;
    background-image: linear-gradient(#fff, #f6f7f7);
    text-align: center;
    &-icon {
      display: block;
      text-align: center;
      font-size: 34px;
      color: $c8C93A1;
      cursor: pointer;
      width: 34px;
      margin: 0 auto;
    }
    &-user {
      position: relative;
      width: 54px;
      height: 54px;
      margin: 20px auto 14px;
      border-radius: 18px;
      cursor: pointer;
      overflow: hidden;
      border: 2px solid $cC8CEDA;
      .img {
        height: 100%;
      }
    }
    &-open {
      cursor: pointer;
      img {
        height: 17.25px;
        width: 25.25px;
      }
    }
  }
}
.playDemo {
  font-size: 14px;
  color: $primary;
  font-weight: 600;
  margin-top: 5vh;
  text-align: center;
}
.title_work{
  color: #878787;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding-right: 8px;
  cursor: auto;
}
.teamN{
  span{
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #878787 !important;
  font-size: 14px!important;
  text-align: center;
  vertical-align: middle;
  }

}
</style>
